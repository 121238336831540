import React, { useState, useEffect, useRef } from 'react';
import '../css/dashboard.css';
import '../css/body.css';
import RegisterForm from './Forms/Registerform';
import Addproductform from './Forms/Addproductform';
import ProductManagement from './Forms/Productmange';
import ProductVariationForm from './Forms/Addvariationsform';
import 'react-image-crop/dist/ReactCrop.css';

const Dashboard = () => {
  const [openMenus, setOpenMenus] = useState({});
  const [currentView, setCurrentView] = useState(''); // Track the current view
  const [currentProductId, setCurrentProductId] = useState(null);
  const toggleMenu = (menu) => {
    setOpenMenus((prevOpenMenus) => ({
      ...prevOpenMenus,
      [menu]: !prevOpenMenus[menu],
    }));
  };

  const handleSubMenuClick = (view, event) => {
    event.stopPropagation();
    setCurrentView(view);
  };

  const handleProductCreated = (productId) => {
    console.log('Newly created product ID:', productId); // Debugging log
    setCurrentProductId(productId);
    setCurrentView('createProductVariation');
  };



  return (
    <div className="dashboard">
      <NavbarDashboard />
      <div className="main-content">
        <Menu
          openMenus={openMenus}
          toggleMenu={toggleMenu}
          handleSubMenuClick={handleSubMenuClick}
        />
        <DashboardSpace
          currentView={currentView}
          currentProductId={currentProductId}
          onProductCreated={handleProductCreated}
        />
      </div>
    </div>
  );
};

const Menu = ({ openMenus, toggleMenu, handleSubMenuClick }) => {
  return (
    <div className="menu">
      <ul>
        <li>Dashboard</li>

        <li onClick={() => toggleMenu('product')} className={openMenus.product ? 'open' : ''}>
          จัดการสินค้า
          <ul className={`submenu ${openMenus.product ? 'open' : ''}`} onClick={(e) => e.stopPropagation()}>
            <li onClick={(e) => handleSubMenuClick('addproductform', e)}>เพิ่มสิ้นค้าใหม่</li>
            <li onClick={(e) => handleSubMenuClick('productmanagement', e)}>จัดการรายการสินค้า</li>
          </ul>
        </li>
        <li onClick={() => toggleMenu('user')} className={openMenus.user ? 'open' : ''}>
          ผู้ใช้
          <ul className={`submenu ${openMenus.user ? 'open' : ''}`} onClick={(e) => e.stopPropagation()}>
            <li onClick={(e) => handleSubMenuClick('createUser', e)}>เพิ่มผู้ใช้</li>
            <li onClick={(e) => handleSubMenuClick('userList', e)}>สมาชิกทั้งหมด</li>
          </ul>
        </li>
        <li onClick={() => toggleMenu('settings')} className={openMenus.settings ? 'open' : ''}>
          ตั้งค่า
          <ul className={`submenu ${openMenus.settings ? 'open' : ''}`} onClick={(e) => e.stopPropagation()}>
            <li>General</li>
            <li>Account</li>
          </ul>
        </li>
        <li onClick={(e) => handleSubMenuClick('createProductVariation', e)}>ติดต่อ Support</li>
      </ul>
    </div>
  );
};

const DashboardSpace = ({ currentView, currentProductId, onProductCreated }) => {
  return (
    <div className="dashboardspace">
      {currentView === 'createUser' && <RegisterForm />}
      {currentView === 'addproductform' && (
        <Addproductform onProductCreated={onProductCreated} />
      )}
      {currentView === 'productmanagement' && <ProductManagement />}
      {currentView === 'createProductVariation' && (
        <ProductVariationForm productId={currentProductId} />
      )}
      {/* Add more views here as needed */}
    </div>
  );
};


const NavbarDashboard = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div>
      <body>
        <nav className="navbar_dashboard">
          <div className="logo">GORGEOUS WOMAN</div>
          <div className="search-box">
            <input type="text" placeholder="Search..." />
          </div>
          <div className="user" onClick={toggleMenu} ref={menuRef}>
            <div className="user-name">ยินดีตอนรับ เบ้นคุง</div>
            <div className={`user-menu ${menuOpen ? 'open' : ''}`}>
              <p>Full Name: Jane Doe</p>
              <p>Email: jane.doe@example.com</p>
              <p>Contact Support</p>
              <hr />
              <button>Log Out</button>
            </div>
          </div>
        </nav>
      </body>
    </div>
  );
};
export default Dashboard;