import React from 'react';
import './sidebar.css'; // Make sure to import your CSS file
import { FaFacebook, FaTwitter, FaLine } from 'react-icons/fa';

const SocialMediaSidebar = () => {
  return (
    <div className="social-media-sidebar">
      <ul>
        <li><a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
          <FaFacebook className="social-icon" />
        </a></li>
        <li><a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">
          <FaTwitter className="social-icon" />
        </a></li>
        <li><a href="https://line.me" target="_blank" rel="noopener noreferrer">
          <FaLine className="social-icon" />
        </a></li>
      </ul>
    </div>
  );
};

export default SocialMediaSidebar;