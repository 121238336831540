import React, { useState, useEffect } from 'react';
import '../css/list.css'; // Make sure to import your CSS file
import '../css/body.css';
import Popup from '../responsivebar/Popup'; // Import the Popup component
import NavBar from '../responsivebar/navbar';
import SocialMediaSidebar from '../responsivebar/sidebar';
import { Link } from "react-router-dom";
//icon
import { FaCartArrowDown } from "react-icons/fa";
import { FaFilter } from "react-icons/fa";
const products = [
  { image: 'style1-1.png', name: 'Shirt 1', price: 19.99, colors: ['#FF0000', '#00FF00', '#0000FF'] },
  // Add more products as needed to ensure there are enough for pagination
];

const ListPage = () => {
  const itemsPerPage = 12; // Number of items per page
  const [currentPage, setCurrentPage] = useState(1);
  const [inputPage, setInputPage] = useState('');
  const [showPopup, setShowPopup] = useState(false);

  const totalPages = Math.ceil(products.length / itemsPerPage);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handlePageInput = (event) => {
    setInputPage(event.target.value);
  };

  const handlePageJump = () => {
    const pageNumber = parseInt(inputPage, 10);
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    } else {
      setShowPopup(true);
    }
    setInputPage('');
  };

  const currentProducts = products.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <div>
      <NavBar />
      <div className="list-page">
        <SocialMediaSidebar />

        <main className="product-list">
          {currentProducts.map((product, index) => (
            <div className="product-card-container" key={index}>
              <Link to='/productDetail'>
                <ProductCard
                  image={product.image}
                  name={product.name}
                  price={product.price}
                  colors={product.colors}
                />
              </Link>
            </div>
          ))}
        </main>
        <aside className="filter">
          <h2><FaFilter />Filter</h2>
          <input type="text" placeholder="Search products..." />
          <button>Search</button>
          <ul>
            <li>Style</li>
            <li>Themes</li>
            <li>Colour</li>
            <li>Accessories</li>
          </ul>
        </aside>
      </div>
      <div className="pagination">
        <button onClick={handlePrevPage} disabled={currentPage === 1}>
          &lt;
        </button>
        {Array.from({ length: totalPages }, (_, index) => (
          <span
            key={index + 1}
            className={currentPage === index + 1 ? 'active' : ''}
            onClick={() => setCurrentPage(index + 1)}
          >
            {index + 1}
          </span>
        ))}
        <button onClick={handleNextPage} disabled={currentPage === totalPages}>
          &gt;
        </button>
        <input
          type="text"
          value={inputPage}
          onChange={handlePageInput}
          placeholder="Jump to page"
        />
        <button onClick={handlePageJump}>Go</button>
      </div>
      {showPopup && <Popup message="Can't go to that page" onClose={() => setShowPopup(false)} />}
    </div>
  );
};

const ProductCard = ({ image, name, price, colors }) => {
  const [hover, setHover] = useState(false);
  const handleError = (event) => {
    event.target.src = 'https://via.placeholder.com/300x400'; // Provide a path to a placeholder image
  };
  return (
    <div
      className="product-card-container"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div className="product-card">
        <div className="product-card-image">
          <img src={image} alt={name} onError={handleError} />
          {hover && <button className="detail-button">+ <FaCartArrowDown /></button>}
        </div>
        <div className="product-card-content">
          <div className="color-options">
            {colors.map((color, index) => (
              <button
                key={index}
                className="color-button"
                style={{ backgroundColor: color }}
              ></button>
            ))}
          </div>
        </div>
      </div>
      <div className="product-card-info">
        <p className="product-name">{name}</p>
        <p className="product-price">${price}</p>
      </div>
    </div>
  );
};

export default ListPage;