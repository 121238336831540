import '../css/homepage.css';
import '../css/body.css';
import '../css/buttons.css';

import NavBar from '../responsivebar/navbar';
import SocialMediaSidebar from '../responsivebar/sidebar';
import React, { useState, useEffect, useRef } from 'react';
import { Link } from "react-router-dom";
//icon

function Homepage({ text }) {
  return (
    <div className="App">
      <div className='homepageimg'>
        <img src='DALL·E GORGEOUS WOMEN.png' className="responsive-image" alt="Gorgeous Woman"></img>
      </div>
      <body className='body-container'>
        <NavBar />
        <SocialMediaSidebar />
        <header className="App-header">
          <h1>YOU ARE GORGEOUS</h1>
        </header>
        <section id="new-shirts">
          <h2>มาใหม่</h2>
          <h3><Link className='view-all-button' to='/listpageallItems' style={{ textDecoration: 'none'}}>ดูทั้งหมด{text} <span className="arrow">&gt;</span></Link></h3>
          <div className="card-container">
            <ShirtCard
              image="DALL·E GORGEOUS WOMEN.png"
              title="Stylish Shirt"
              description="A stylish shirt for all occasions."
            />
            <ShirtCard
              image="DALL·EAn Asian woman standing with a confident pose.png"
              title="Casual Shirt"
              description="A casual shirt for everyday wear."
            />
            <ShirtCard
              image="DALL·Ewoman wearing a black tank top.png"
              title="Formal Shirt"
              description="A formal shirt for special occasions."
            />
            <ShirtCard
              image="DALL·EGORGEOUSWOMEN.png"
              title="Formal Shirt"
              description="A formal shirt for special occasions."
            />
          </div>
          <section id="styles">
            <h2>Select Your Style</h2>
            <div className="card-container">
              <StyleCard
                defaultImage="style1-1.png"
                hoverImage="style1-2.png"
                title="Casual"
              />
              <StyleCard
                defaultImage="style2-1.png"
                hoverImage="style2-2.png"
                title="Formal"
              />
              <StyleCard
                defaultImage="style3-1.png"
                hoverImage="style3-2.png"
                title="Sporty"
              />
              <StyleCard
                defaultImage="style-default3.jpg"
                hoverImage="style-hover3.jpg"
                title="Sporty"
              />
              <StyleCard
                defaultImage="style-default3.jpg"
                hoverImage="style-hover3.jpg"
                title="Sporty"
              />
              <StyleCard
                defaultImage="style-default3.jpg"
                hoverImage="style-hover3.jpg"
                title="Sporty"
              />
            </div>
          </section>
        </section>
      </body>
    </div>
  );
}

const ShirtCard = ({ image, title, description }) => {
  const cardRef = useRef(null);
  const [inView, setInView] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            setInView(true);
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.1 }
    );

    const currentRef = cardRef.current;

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [cardRef]);
  return (

    <div className="card" >
      <div ref={cardRef}
        className={`style-card ${inView ? 'fade-in' : ''}`}>
        <div className="card-image">
          <img src={image} alt={title} />
        </div>
        <div className="card-content">
          <h3>{title}</h3>
          <p>{description}</p>
        </div>
      </div>
    </div>
  );
};

const StyleCard = ({ defaultImage, hoverImage, title }) => {
  const cardRef = useRef(null);
  const [inView, setInView] = useState(false);
  const [hover, setHover] = useState(false); // Define hover state

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            setInView(true);
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.1 }
    );

    const currentRef = cardRef.current;

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [cardRef]);

  return (
    <div
      ref={cardRef}
      className={`style-card ${inView ? 'fade-in' : ''}`}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div className="style-card-image">
        <img src={hover ? hoverImage : defaultImage} alt={title} />
      </div>
      <div className={`style-card-content ${hover ? 'hover' : ''}`}>
        <h3>{title}</h3>
      </div>
    </div>
  );
};

export default Homepage;
