import React, { useState } from 'react';
import '../css/loginpage.css';
import '../css/body.css';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const LoginPage = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState('');
  
    const handleSubmit = (e) => {
      e.preventDefault();
      if (username === '' || password === '') {
        setError('โปรดกรอกชื่อผู้ใช้ และรหัสผ่าน');
      } else {
        // Handle successful login
        setError('');
        // Perform login action
      }
    };
  
    return (
      <div className="login-page">
        <div className="login-box">
          <h2>GORGEOUS WOMAN</h2>
          
          <form onSubmit={handleSubmit}>
            <div className="input-container">
              <label htmlFor="username">ไอดีผู้ใช้</label>
              <input
                type="text"
                id="username"
                name="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
            <div className="input-container">
              <label htmlFor="password">รหัสผ่าน</label>
              <div className="password-container">
                <input
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  name="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <span
                  className="password-toggle-icon"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <FaEye /> : < FaEyeSlash/>}
                </span>
              </div>
            </div>
            <button className='login_button' type="submit">เข้าสู่ระบบ</button>
            {error && <p className="error">{error}</p>}
          </form>
        </div>
      </div>
    );
  };

export default LoginPage;