import React, { useState, useRef } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import Modal from 'react-modal';
import './registerform.css';
import { API_ENDPOINTS } from '../../connectapi'
import axios from 'axios';

Modal.setAppElement('#root');

const RegisterForm = () => {
  const [formData, setFormData] = useState({
    user_userid: '',
    user_password: '',
    user_repeat_password: '',
    user_name: '',
    user_middlename: '',
    user_lastname: '',
    user_img: '',
    user_email: '',
    user_phone: '',
    user_position: '',
    user_status: '',
  });
  const [imagePreview, setImagePreview] = useState(null);
  const [crop, setCrop] = useState({ aspect: 1 });
  const [completedCrop, setCompletedCrop] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isUserIdTaken, setIsUserIdTaken] = useState(false);
  const [upImg, setUpImg] = useState();
  const imageRef = useRef(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setUpImg(URL.createObjectURL(file));
      setModalIsOpen(true);
    }
  };

  const onImageLoaded = (image) => {
    imageRef.current = image;
  };

  const handleCropComplete = (crop) => {
    setCompletedCrop(crop);
    makeClientCrop(crop);
  };

  const makeClientCrop = async (crop) => {
    if (imageRef.current && crop.width && crop.height) {
      const croppedImageUrl = await getCroppedImg(
        imageRef.current,
        crop,
        'newFile.jpeg'
      );
      setImagePreview(croppedImageUrl);
    }
  };

  const getCroppedImg = (image, crop, fileName) => {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          console.error('Canvas is empty');
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(imagePreview);
        const fileUrl = window.URL.createObjectURL(blob);
        resolve(fileUrl);
      }, 'image/jpeg');
    });
  };

  const handleCropButton = () => {
    if (completedCrop && imageRef.current) {
      makeClientCrop(completedCrop);
      setModalIsOpen(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Validate passwords match
    if (formData.user_password !== formData.user_repeat_password) {
      alert("Passwords do not match!");
      return;
    }
  
    // Check if user_userid already exists
    try {
      const response = await axios.post(API_ENDPOINTS.checkUserId, { user_userid: formData.user_userid });
      console.log('API response:', response.data);
      if (response.data.userExists) {
        setIsUserIdTaken(true); // Show the modal if user ID exists
        return;
      }
    } catch (error) {
      console.error('Error checking user ID:', error.response ? error.response.data : error.message);
      alert('An error occurred while checking the user ID');
      return;
    }
  
    // Proceed with form submission
    const data = new FormData();
    data.append('user_userid', formData.user_userid);
    data.append('user_password', formData.user_password);
    data.append('user_name', formData.user_name);
    data.append('user_middlename', formData.user_middlename);
    data.append('user_lastname', formData.user_lastname);
    data.append('user_email', formData.user_email);
    data.append('user_phone', formData.user_phone);
    data.append('user_position', formData.user_position);
    data.append('user_status', formData.user_status);
  
    // Append the cropped image file to the form data
    if (imagePreview) {
      try {
        const response = await fetch(imagePreview);
        const blob = await response.blob();
        const file = new File([blob], 'cropped_image.jpeg', { type: 'image/jpeg' });
        data.append('user_img', file);
      } catch (error) {
        console.error('Error processing the image:', error);
        alert('An error occurred while processing the image');
        return;
      }
    }
  
    // Submit the form data to create the user
    try {
      const createUserResponse = await axios.post(API_ENDPOINTS.createUser, data);
      alert('User created successfully');
      // Optionally clear the form or redirect
    } catch (error) {
      console.error('Error creating user:', error);
      alert('Error creating user');
    }
  };
  return (
    <div className="register-form">
      <h2>เพิ่มผู้ใช้</h2>
      <form onSubmit={handleSubmit}>
        <label>
          ไอดีผู้ใช้
          <input type="text" name="user_userid" value={formData.user_userid} onChange={handleChange} required />
        </label>
        <label>
          รหัสผ่าน:
          <input type="password" name="user_password" value={formData.user_password} onChange={handleChange} required />
        </label>
        <label>
          ยืนยันรหัสผ่าน:
          <input type="password" name="user_repeat_password" value={formData.user_repeat_password} onChange={handleChange} required />
        </label>
        <div className="name-fields">
          <label>
            ชื่อ:
            <input type="text" name="user_name" value={formData.user_name} onChange={handleChange} required />
          </label>
          <label>
            ชื่อกลาง:
            <input type="text" name="user_middlename" value={formData.user_middlename} onChange={handleChange} />
          </label>
          <label>
            นามสกุล:
            <input type="text" name="user_lastname" value={formData.user_lastname} onChange={handleChange} required />
          </label>
        </div>
        <div className="image-upload">
          <label>
            รูปโปรไฟล์:
            <input type="file" name="user_img" accept="image/*" onChange={handleImageUpload} />
          </label>
          <div className="image-preview">
            {imagePreview ? (
              <img src={imagePreview} alt="Preview" className="image-preview-img" />
            ) : (
              <img src="avatar.jpeg" alt="Default Avatar" className="image-preview-img" />
            )}
          </div>
        </div>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={() => setModalIsOpen(false)}
          contentLabel="Image Crop"
          className="Modal"
          overlayClassName="Overlay"
        >
          <h2>ปรับขนาดรูป</h2>
          {upImg && (
            <ReactCrop
              src={upImg}
              crop={crop}
              onImageLoaded={onImageLoaded}
              onChange={(newCrop) => setCrop(newCrop)}
              onComplete={handleCropComplete}
            />
          )}
          <button type="button" onClick={handleCropButton}>Crop</button>
          <button type="button" onClick={() => setModalIsOpen(false)}>Cancel</button>
        </Modal>
        <div className="contact-fields">
          <label>
            Email:
            <input type="email" name="user_email" value={formData.user_email} onChange={handleChange} required />
          </label>
          <label>
            เบอร์ติดต่อ:
            <input type="tel" name="user_phone" value={formData.user_phone} onChange={handleChange} required />
          </label>
        </div>
        <div className="status-fields">
          <label>
            ตำแหน่ง:
            <select name="user_position" value={formData.user_position} onChange={handleChange} required>
              <option value="admin">Admin</option>
              <option value="manager">Manager</option>
            </select>
          </label>
          <label>
            Status:
            <select name="user_status" value={formData.user_status} onChange={handleChange} required>
              <option value="active">Active</option>
              <option value="inactive">Inactive</option>
            </select>
          </label>
        </div>
        <button type="submit">ยืนยัน</button>
      </form>

      <Modal
        isOpen={isUserIdTaken}
        onRequestClose={() => setIsUserIdTaken(false)}
        contentLabel="User ID Exists"
        className="Modal"
        overlayClassName="Overlay"
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
          },
        }}
      >
        <h2>User ID already exists</h2>
        <p>Please choose a different User ID.</p>
        <button type="button" onClick={() => setIsUserIdTaken(false)}>Close</button>
      </Modal>
    </div>
  );
};

export default RegisterForm;
