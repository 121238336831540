const API_BASE_URL = 'http://localhost:3002';

export const API_ENDPOINTS = {
  createUser: `${API_BASE_URL}/api/users`,
  checkUserId: `${API_BASE_URL}/api/check_userid`,
  
  createProduct: `${API_BASE_URL}/api/products`,

  getStylesByCategory: (categoryId) => `${API_BASE_URL}/api/styles/${categoryId}`,
  addStyle: `${API_BASE_URL}/api/styles`,
  
  addColor: `${API_BASE_URL}/api/colors`,
  getColors: `${API_BASE_URL}/api/colors`,
  
  
  getProduct: (pro_id) => `${API_BASE_URL}/api/product/${pro_id}`,
  getSizesByType: (proty_id) => `${API_BASE_URL}/api/sizes/${proty_id}`,
  addSize: `${API_BASE_URL}/api/sizes`,

  createProductVariation: `${API_BASE_URL}/api/variations`,
  addProductImage: `${API_BASE_URL}/api/images`,

  uploadImage: `${API_BASE_URL}/upload-images`,
  createProductVariation: `${API_BASE_URL}/api/variations`,
}