import React, { useState } from 'react';
import './productmange.css';
import axios from 'axios';
import './productmange.css';
import { API_ENDPOINTS } from '../../connectapi';

const ProductManagement = () => {
  const [products, setProducts] = useState([
    {
      pro_ID : 1,
      pro_name: 'Ocean',
      proty_id 	: 'Furniture',
      prosty_id : 'Active',
      pro_price: "112",
      pro_detail	: "36sadfasdfasdfasdfsadf",
      variations: [
        { id: 1, image: 'style1-1.png', type: 'Chair', style: 'Modern', color: 'Blue', size: 'Medium' },
        { id: 2, image: 'style1-2.png', type: 'Table', style: 'Modern', color: 'White', size: 'Large' }
      ]
    },
    // Add more products with variations
  ]);
  const [expandedProductId, setExpandedProductId] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleExpand = (productId, event) => {
    if (event.target.tagName !== 'BUTTON') {
      setExpandedProductId(expandedProductId === productId ? null : productId);
    }
  };

  const filteredProducts = products.filter(product =>
    product.pro_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="product-management">
      <header className="header">
        <h2>Products</h2>
        <div className="header-actions">
          <input
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={handleSearch}
            className="search-bar"
          />
        </div>
        <div>
            
        </div>
      </header>
      <table className="product-table">
        <thead>
          <tr>
            <th>ชื่อสินค้า</th>
            <th>ประเภท</th>
            <th>สไตล์</th>
            <th>ราคา</th>
            <th>รายละเอียด</th>
            <th>การจัดการ</th>
          </tr>
        </thead>
        <tbody>
          {filteredProducts.map((product, index) => (
            <React.Fragment key={product.pro_ID}>
              <tr onClick={(event) => handleExpand(product.pro_ID, event)}>
                <td>
                  {product.pro_name}
                </td>
                <td>{product.proty_id}</td>
                <td>{product.prosty_id}</td>
                <td>{product.pro_price}</td>
                <td>{product.pro_detail}</td>
                <td>
                  <button className="addmorebutton">Add Variations</button>
                  <button className="addmorebutton">Edit Product</button>
                  <button className="delbutton">Delete Product</button>
                </td>
              </tr>
              <tr className={`variation-row ${expandedProductId === product.pro_ID ? 'open' : ''}`}>
                <td colSpan="7">
                  <table className="variation-table">
                    <thead>
                      <tr>
                        <th>Image</th>
                        <th>Type</th>
                        <th>Style</th>
                        <th>Color</th>
                        <th>Size</th>
                        <th>Manage</th>
                      </tr>
                    </thead>
                    <tbody>
                      {product.variations.map(variation => (
                        <tr key={variation.id}>
                          <td>
                            <div className="variation-image-wrapper">
                              <img
                                src={variation.image}
                                alt={`${variation.type} ${variation.style}`}
                                className="variation-image"
                              />
                            </div>
                          </td>
                          <td>{variation.type}</td>
                          <td>{variation.style}</td>
                          <td>{variation.color}</td>
                          <td>{variation.size}</td>
                          <td>
                            <button className="addmorebutton">Edit Product</button>
                            <button className="delbutton">Delete Product</button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ProductManagement;
