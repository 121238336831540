import '../css/layout.css';
import '../css/body.css';
import React from 'react';

//icon
import { FaXTwitter, FaLine } from "react-icons/fa6";
import { FaFacebookF } from "react-icons/fa";

function Layout() {
    return (
        <div className="App">
            <div className='sticky'>
                <aside class="_social-links">
                    <ul class="_links-list">
                        <li class="_social-link"><a href="https://x.com/HerbalThp"><i><FaXTwitter /></i></a></li>
                        <li class="_social-link"><a href="https://www.facebook.com/THPHerbal/"><i><FaFacebookF /></i></a></li>
                        <li class="_social-link"><a href="https://line.me/ti/p/@THP247"><i><FaLine /></i></a></li>
                    </ul>
                </aside>
            </div>
        </div>
    );
}
export default Layout;
