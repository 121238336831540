import React, { useState, useEffect } from 'react';
import { API_ENDPOINTS } from '../../connectapi';
import axios from 'axios';
import './addproductform.css';

const CreateProductForm = ({ onProductCreated }) => {
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [styles, setStyles] = useState([]);
    const [formData, setFormData] = useState({
        pro_style: '',
        pro_name: '',
        pro_detail: '',
    });

    const categories = [
        { id: 1, name: 'Top', icon: '👕' },
        { id: 2, name: 'Bottom', icon: '👖' },
        { id: 3, name: 'Hat', icon: '🧢' },
        { id: 4, name: 'Accessories', icon: '🎒' },
        { id: 5, name: 'Shoes', icon: '👟' },
    ];

    const [addingNewStyle, setAddingNewStyle] = useState(false);
    const [newStyle, setNewStyle] = useState('');

    useEffect(() => {
        if (selectedCategory) {
            console.log('Fetching styles for category:', selectedCategory.id);
            axios.get(API_ENDPOINTS.getStylesByCategory(selectedCategory.id))
                .then(response => setStyles(response.data))
                .catch(error => console.error('Error fetching styles:', error));
        }
    }, [selectedCategory]);

    const handleCategorySelect = (category) => {
        console.log('Category selected:', category); // Debugging log
        setSelectedCategory(category);
        setStyles([]); // Reset styles when a new category is selected
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleNewStyleSubmit = async () => {
        if (newStyle.trim()) {
            try {
                console.log("Adding new style with proty_id:", selectedCategory.id); // Debugging
                const response = await axios.post(API_ENDPOINTS.addStyle, {
                    prosty_name: newStyle,
                    proty_id: selectedCategory.id,
                });

                setStyles(prevStyles => [...prevStyles, response.data]);
                setFormData(prevData => ({ ...prevData, pro_style: response.data.prosty_id }));
                setAddingNewStyle(false);
                setNewStyle('');
            } catch (error) {
                console.error('Error adding new style:', error);
                alert('Error adding new style');
            }
        }
    };

    const handleNewStyleCancel = () => {
        setAddingNewStyle(false);
        setNewStyle('');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        // Debugging: Check if selectedCategory is correctly set
        if (!selectedCategory) {
            console.error('selectedCategory is undefined');
            alert('Please select a product category before submitting.');
            return;
        }
    
        console.log('selectedCategory:', selectedCategory); // Check what is stored in selectedCategory
    
        if (!selectedCategory.id) {
            console.error('selectedCategory.id is undefined or missing');
            alert('Please select a product category before submitting.');
            return;
        }
    
        const payload = {
            pro_name: formData.pro_name,
            pro_detail: formData.pro_detail,
            pro_style: formData.pro_style,
            selectedCategory: selectedCategory, // Send the entire selectedCategory object
        };
    
        try {
            const response = await axios.post(API_ENDPOINTS.createProduct, payload);
    
            if (response.status === 201) {
                alert('Product created successfully!');
                onProductCreated(response.data.product.pro_id); // Pass the product ID to the parent component
            } else {
                console.error('Failed to create product');
                alert('Error creating product');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('Error creating product');
        }
    };
    return (
        <div className="create-product-form">
            {!selectedCategory ? (
                <>
                    <p className="advice-text">Please select a product category to begin:</p>
                    <div className="category-buttons">
                        {categories.map((category) => (
                            <button
                                key={category.id}
                                onClick={() => handleCategorySelect(category)}
                                className="category-button"
                            >
                                <span className="category-icon">{category.icon}</span>
                                {category.name}
                            </button>
                        ))}
                    </div>
                </>
            ) : (
                <div className="product-form">
                    <div className="product-form-header">
                        <h2>เพิ่มสิ้นค้าประเภท {selectedCategory.name}</h2>
                        <button onClick={() => setSelectedCategory(null)} className="back-button">
                            เลือกใหม่
                        </button>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <label>
                            Product Style:
                            {!addingNewStyle ? (
                                <select
                                    name="pro_style"
                                    value={formData.pro_style}
                                    onChange={(e) => {
                                        if (e.target.value === 'add_new') {
                                            setAddingNewStyle(true);
                                        } else {
                                            handleChange(e);
                                        }
                                    }}
                                    required
                                >
                                    <option value="">Select Style</option>
                                    {styles.map((style) => (
                                        <option key={style.prosty_id} value={style.prosty_id}>
                                            {style.prosty_name}
                                        </option>
                                    ))}
                                    <option value="add_new">+ Add new Style</option>
                                </select>
                            ) : (
                                <div className="new-style-input">
                                    <input
                                        type="text"
                                        value={newStyle}
                                        onChange={(e) => setNewStyle(e.target.value)}
                                        placeholder="Enter new style"
                                        required
                                    />
                                    <button type="button" onClick={handleNewStyleSubmit}>✔️</button>
                                    <button type="button" onClick={handleNewStyleCancel}>❌</button>
                                </div>
                            )}
                        </label>
                        <label>
                            Product Name:
                            <input type="text" name="pro_name" value={formData.pro_name} onChange={handleChange} required />
                        </label>
                        <label>
                            Product Detail:
                            <textarea name="pro_detail" value={formData.pro_detail} onChange={handleChange} required />
                        </label>
                        <center>
                            <button type="submit">Add Product</button>
                        </center>
                    </form>
                </div>
            )}
        </div>
    );
};
export default CreateProductForm;
