import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import React, { Suspense } from 'react';
import './index.css';
import Homepage from './pages/Homepage';
import Layout from './pages/Layout'
import ListPage from './pages/Listpage'
import reportWebVitals from './reportWebVitals';
import Dashboard from "./pages/Dashboard";
import LoginPage from "./pages/LoginPage";
import ProductDetail from "./pages/Productdetail";

export default function App() {
  return (
    <BrowserRouter>
      <Suspense fallback={<div>loading</div>}>
        <Routes path="/" element={<Layout />}>
          <Route index element={<Homepage />} />
          <Route path="/listpageallItems" element={<ListPage />} />
          <Route path="/dashboardgwpage" element={<Dashboard />} />
          <Route path="/logingwpage" element={<LoginPage />} />
          <Route path="/productDetail" element={<ProductDetail />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
