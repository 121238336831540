import React, { useState, useEffect } from 'react';
import { SketchPicker } from 'react-color';
import axios from 'axios';
import './addvariationsform.css';
import { API_ENDPOINTS } from '../../connectapi';

const AddProductForm = ({ productId }) => { // Receive pro_id from parent
  console.log('Received pro_id:', productId);
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [draggedImage, setDraggedImage] = useState(null);
  const [formData, setFormData] = useState({
    prosty_id: '',
    pro_name: '',
    pro_gender: '',
    provariation_url: '',
    pro_link: '',
    procol_id: '',
  });
  const [productType, setProductType] = useState(''); // To store the product type name
  const [selectedStyleName, setSelectedStyleName] = useState('');
  const [sizes, setSizes] = useState([]);
  const [addingNewSize, setAddingNewSize] = useState(false);
  const [newSizeName, setNewSizeName] = useState('');
  const [colors, setColors] = useState([]);
  const [addingNewColor, setAddingNewColor] = useState(false);
  const [newColorName, setNewColorName] = useState('');
  const [newColorHex, setNewColorHex] = useState('#ffffff');
  const [selectedColorHex, setSelectedColorHex] = useState('');

  useEffect(() => {
    fetchProductData(productId);
    axios.get(API_ENDPOINTS.getColors)
      .then(response => setColors(response.data))
      .catch(error => console.error('Error fetching colors:', error));
  }, [productId]);


  const handleImageUpload = (e) => {
    const files = Array.from(e.target.files);
    const newImages = files.map(file => URL.createObjectURL(file));
    setImages((prevImages) => {
      const updatedImages = [...prevImages, ...newImages].slice(0, 3);
      if (!selectedImage && updatedImages.length > 0) {
        setSelectedImage(updatedImages[0]);
      }
      return updatedImages;
    });

  };
  const fetchProductData = async (pro_id) => {
    try {
      const productResponse = await axios.get(API_ENDPOINTS.getProduct(pro_id));
      const productData = productResponse.data;

      console.log('Fetched product data:', productData); // Log the entire product data

      setProductType({ id: productData.productType.proty_id, name: productData.productType.proty_name });

      // Set the product name
      setFormData(prevData => ({
        ...prevData,
        pro_name: productData.productName || 'Unnamed Product', // Fallback to a default name if productName is missing
      }));

      // Set the selected style name and ID
      if (productData.styles.length > 0) {
        const selectedStyle = productData.styles[0];
        setFormData(prevData => ({ ...prevData, prosty_id: selectedStyle.prosty_id }));
        setSelectedStyleName(selectedStyle.prosty_name);
      }

      // Fetch sizes based on product type
      const sizeResponse = await axios.get(API_ENDPOINTS.getSizesByType(productData.productType.proty_id));
      setSizes(sizeResponse.data);
    } catch (error) {
      console.error('Error fetching product data:', error);
    }
  };


  const handleNewSizeSubmit = async () => {
    if (newSizeName.trim()) {
      try {
        const response = await axios.post(API_ENDPOINTS.addSize, {
          prosize_name: newSizeName,
          proty_id: productType.id, // Correctly reference the ID here
        });

        setSizes(prevSizes => [...prevSizes, response.data]);
        setFormData(prevData => ({ ...prevData, prosize_id: response.data.prosize_id }));
        setAddingNewSize(false);
        setNewSizeName('');
      } catch (error) {
        console.error('Error adding new size:', error);
        alert('Error adding new size');
      }
    }
  };

  const handleNewSizeCancel = () => {
    setAddingNewSize(false);
    setNewSizeName('');
  };

  const handleImageClick = (index) => {
    setSelectedImage(images[index]);
  };

  const handleDragStart = (index) => {
    setDraggedImage(index);
  };

  const handleDragOver = (index) => {
    if (draggedImage === null) return;
    const newImages = [...images];
    const draggedImg = newImages[draggedImage];
    newImages.splice(draggedImage, 1);
    newImages.splice(index, 0, draggedImg);
    setDraggedImage(index);
    setImages(newImages);
  };

  const handleImageRemove = (index) => {
    const newImages = images.filter((_, i) => i !== index);
    setImages(newImages);
    if (selectedImage === images[index]) {
      setSelectedImage(newImages.length > 0 ? newImages[0] : null);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "procol_id") {
      const selectedColor = colors.find(color => color.procol_id.toString() === value.toString());
      if (selectedColor) {
        setFormData(prevData => ({
          ...prevData,
          procol_id: selectedColor.procol_id,
        }));
        setSelectedColorHex(selectedColor.procol_hexcode);
      }
    } else {
      setFormData(prevData => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleNewColorSubmit = async () => {
    if (newColorName.trim()) {
      try {
        const response = await axios.post(API_ENDPOINTS.addColor, {
          procol_name: newColorName,
          procol_hexcode: newColorHex,
        });

        setColors(prevColors => [...prevColors, response.data]);
        setFormData(prevData => ({ ...prevData, procol_id: response.data.procol_hexcode }));
        setSelectedColorHex(newColorHex); // Update the preview to the newly added color
        setAddingNewColor(false);
        setNewColorName('');
        setNewColorHex('#ffffff');
      } catch (error) {
        console.error('Error adding new color:', error);
        alert('Error adding new color');
      }
    }
  };


  const handleNewColorCancel = () => {
    setAddingNewColor(false);
    setNewColorName('');
    setNewColorHex('#ffffff');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const imageUploadPromises = images.map((image) => {
        const formData = new FormData();
        formData.append('file', image);

        return axios.post(API_ENDPOINTS.uploadImage, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
      });

      const uploadedImages = await Promise.all(imageUploadPromises);
      const imageUrls = uploadedImages.map(response => response.data.url);

      const variationResponse = await axios.post(API_ENDPOINTS.createProductVariation, {
        pro_id: productId,
        procol_id: formData.procol_id,
        prosize_id: formData.prosize_id,
        provariation_url: formData.provariation_url,
        provariation_price: formData.provariation_price,
      });

      if (variationResponse.status === 201) {
        const provariation_id = variationResponse.data.provariation_id;

        const imageInsertPromises = imageUrls.map((url, index) => {
          return axios.post(API_ENDPOINTS.addProductImage, {
            proimg_order: index + 1,
            proimg_url: url,
            provariation_id: provariation_id,
          });
        });

        await Promise.all(imageInsertPromises);

        alert('Variation and images added successfully!');
      } else {
        alert('Error creating variation');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Error creating variation');
    }
  };

  return (
    <div className="add-product-form">
      {formData.pro_name ? (
        <h2>ชื่อสินค้า: {formData.pro_name}</h2>
      ) : (
        <h2>Loading product data...</h2>
      )}
      <form onSubmit={handleSubmit} className='varform'>
        <div className="image-upload-section">
          <div className="main-image-preview">
            {selectedImage ? (
              <img src={selectedImage} alt="Selected" />
            ) : (
              <p>No image selected</p>
            )}
          </div>
          <div className="thumbnail-previews">
            {images.map((image, index) => (
              <div
                key={index}
                className="thumbnail-wrapper"
                onClick={() => handleImageClick(index)}
                draggable
                onDragStart={() => handleDragStart(index)}
                onDragOver={() => handleDragOver(index)}
              >
                <img src={image} alt={`Thumbnail ${index + 1}`} />
                <button type="button" className="remove-image" onClick={() => handleImageRemove(index)}>X</button>
              </div>
            ))}
          </div>
          {images.length < 3 && (
            <div className="add-image-button">
              <label>
                <input
                  type="file"
                  onChange={handleImageUpload}
                  accept="image/*"
                  multiple
                />
                <span>Add Images</span>
              </label>
            </div>
          )}
        </div>
        <div className="product-details">
          <label>
            ประเภทสินค้า:
            <input type="text" value={productType.name} readOnly />
          </label>
          <label>
            ทรงของสิ้นค้า:
            <input type="text" value={selectedStyleName} readOnly />
          </label>
          <label>
            ขนาด:
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {!addingNewSize ? (
                <>
                  <select
                    name="prosize_id"
                    value={formData.prosize_id}
                    onChange={(e) => {
                      if (e.target.value === 'add_new') {
                        setAddingNewSize(true);
                      } else {
                        handleChange(e);
                      }
                    }}
                    required
                  >
                    <option value="">เลือกขนาด</option>
                    {sizes.map(size => (
                      <option key={size.prosize_id} value={size.prosize_id}>
                        {size.prosize_name}
                      </option>
                    ))}
                    <option value="add_new">+ Add new Size</option>
                  </select>
                </>
              ) : (
                <div className="new-size-input">
                  <input
                    type="text"
                    value={newSizeName}
                    onChange={(e) => setNewSizeName(e.target.value)}
                    placeholder="Enter new size name"
                    required
                  />
                  <button type="button" onClick={handleNewSizeSubmit}>✔️</button>
                  <button type="button" onClick={handleNewSizeCancel}>❌</button>
                </div>
              )}
            </div>
          </label>
          <label>
            ราคาสินค้า:
            <input
              type="number"
              name="provariation_price"
              value={formData.provariation_price}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            URL:
            <input
              type="text"
              name="provariation_url"
              value={formData.provariation_url}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            รูปแบบโครงสร้าง:
            <select
              name="pro_gender"
              value={formData.pro_gender}
              onChange={handleChange}
              required
            >
              <option value="">Select Gender</option>
              <option value="male">ชาย</option>
              <option value="female">หญิง</option>
            </select>
          </label>
          <label>
            สีของสินค้า:
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {selectedColorHex && (
                <span
                  style={{
                    backgroundColor: selectedColorHex,
                    width: '20px',
                    height: '20px',
                    borderRadius: '50%',
                    display: 'inline-block',
                    marginLeft: '10px',
                    border: '1px solid #000',
                  }}
                ></span>
              )}
              <select
                style={{ paddingLeft: '5%' }}
                name="procol_id"
                value={formData.procol_id}
                onChange={(e) => {
                  const selectedColorId = e.target.value;
                  if (selectedColorId === 'add_new') {
                    setAddingNewColor(true);
                  } else {
                    const selectedColor = colors.find(color => color.procol_id && color.procol_id.toString() === selectedColorId);
                    if (selectedColor) {
                      setFormData(prevData => ({
                        ...prevData,
                        procol_id: selectedColor.procol_id,
                      }));
                      setSelectedColorHex(selectedColor.procol_hexcode);
                    } else {
                      console.error('Selected color not found');
                    }
                  }
                }}
                required
              >
                <option value="">เลือกสี</option>
                {colors.map((color) => (
                  <option key={color.procol_id} value={color.procol_id ? color.procol_id.toString() : ''}>
                    {color.procol_name}
                  </option>
                ))}
                <option value="add_new">+ Add new Color</option>
              </select>

            </div>
          </label>

          {addingNewColor && (
            <div className="new-color-input">
              <input
                type="text"
                value={newColorName}
                onChange={(e) => setNewColorName(e.target.value)}
                placeholder="Enter new color name"
                required
              />
              <SketchPicker
                color={newColorHex}
                onChangeComplete={(color) => setNewColorHex(color.procol_id)}
              />
              <button type="button" onClick={handleNewColorSubmit}>✔️</button>
              <button type="button" onClick={handleNewColorCancel}>❌</button>
            </div>
          )}

          <button type="submit">Add Product</button>
        </div>
      </form>
    </div>
  );
};

export default AddProductForm;
