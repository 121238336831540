import './navbar.css';
import React, { useState, useEffect, useRef } from 'react';
import { Outlet, Link } from 'react-router-dom';

const NavBar = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [openSection, setOpenSection] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };

    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpenSection(null);
        setMenuOpen(false); // Ensure menu closes on outside click
      }
    };

    window.addEventListener('scroll', handleScroll);
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleSectionToggle = (section) => {
    if (openSection !== section) {
      setOpenSection(section);
      setMenuOpen(true);
    }
    // If the section is already open, do nothing.
  };
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    if (menuOpen) {
      // Ensure the open section is closed when the menu is toggled off
      setOpenSection(null);
    }
  };

  return (
    <>
    <nav className={`navbar ${isScrolled ? 'scrolled' : ''} ${menuOpen && !isScrolled ? 'menu-open' : ''}`}>
      <div>
        <Link className="navbar-brand" to="/" style={{ textDecoration: 'none' }}>
          GORGEOUS WOMAN
        </Link>
      </div>
      <div className="hamburger" onClick={toggleMenu}>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <ul className={`navbar-links ${menuOpen ? 'open' : ''}`}>
        <li
          onClick={() => handleSectionToggle('threads')}
          className={openSection === 'threads' ? 'active' : ''}
        >
          <a href="#threads">Threads</a>
        </li>
        <li
          onClick={() => handleSectionToggle('accessories')}
          className={openSection === 'accessories' ? 'active' : ''}
        >
          <a href="#accessories">Accessories</a>
        </li>
        <li
          onClick={() => handleSectionToggle('themes')}
          className={openSection === 'themes' ? 'active' : ''}
        >
          <a href="#themes">Themes</a>
        </li>
        <li
          onClick={() => handleSectionToggle('news')}
          className={openSection === 'news' ? 'active' : ''}
        >
          <a href="#news">News</a>
        </li>
      </ul>
    </nav>

    <div className={`dropdown-menu ${openSection ? 'open' : 'close'}`} ref={dropdownRef}>
      <div className="dropdown-content">
        {openSection === 'threads' && (
          <ul>
            <li>Shirts</li>
            <ul>
              <li>Product 1</li>
              <li>Product 2</li>
              <li>Product 3</li>
            </ul>
            <li>Bottoms</li>
            <ul>
              <li>Product 1</li>
              <li>Product 2</li>
              <li>Product 3</li>
            </ul>
            <li>Shoes</li>
            <ul>
              <li>Product 1</li>
              <li>Product 2</li>
              <li>Product 3</li>
            </ul>
          </ul>
        )}
        {/* Repeat for other sections */}
      </div>
      <Outlet />
    </div>
  </>
);
};


export default NavBar;
