import React from 'react';
import './popup.css';

const Popup = ({ message, onClose }) => {
  const handleClickOutside = (event) => {
    if (event.target.classList.contains('popup-overlay')) {
      onClose();
    }
  };

  return (
    <div className="popup-overlay" onClick={handleClickOutside}>
      <div className="popup-box">
        <p>{message}</p>
        <button onClick={onClose}>Okay</button>
      </div>
    </div>
  );
};

export default Popup;