import React, { useState } from 'react';
import '../css/productdetail.css';
import '../css/body.css';
import NavBar from '../responsivebar/navbar';

const ProductDetail = () => {
  const productdata = {
    name: "Cool T-Shirt",
    price: 29.99,
    description: "This is a cool t-shirt with an awesome design.",
    images: [
      "style1-1.png",
      "style1-2.png",
      "style2-1.png"
    ],
    sizes: ["S", "M", "L", "XL"],
    colors: ["Red", "Blue", "Green"]
  };

  return (
    <div className="App">
      <NavBar />
      <GetProductDetail productdata={productdata} />
    </div>
  );
};

const GetProductDetail = ({ productdata }) => {
  const [selectedImage, setSelectedImage] = useState(productdata.images[0]);
  const [selectedSize, setSelectedSize] = useState(productdata.sizes[0]);
  const [selectedColor, setSelectedColor] = useState(productdata.colors[0]);

  return (
    <div className="product-detail">
      <div className="product-images">
        <div className="main-image">
          <img src={selectedImage} alt={productdata.name} />
        </div>
        <div className="thumbnail-images">
          {productdata.images.map((image, index) => (
            <img 
              key={index} 
              src={image} 
              alt={`Thumbnail ${index + 1}`} 
              onClick={() => setSelectedImage(image)} 
            />
          ))}
        </div>
      </div>
      <div className="product-info">
        <h1>{productdata.name}</h1>
        <p className="price">${productdata.price}</p>
        <p className="description">{productdata.description}</p>
        <div className="product-options">
          <label>Size:</label>
          <div className="size-options">
            {productdata.sizes.map((size, index) => (
              <button 
                key={index} 
                className={size === selectedSize ? 'active' : ''} 
                onClick={() => setSelectedSize(size)}
              >
                {size}
              </button>
            ))}
          </div>
        </div>
        <div className="product-options">
          <label>Color:</label>
          <div className="color-options">
            {productdata.colors.map((color, index) => (
              <button 
                key={index} 
                className={color === selectedColor ? 'active' : ''} 
                onClick={() => setSelectedColor(color)}
              >
                {color}
              </button>
            ))}
          </div>
        </div>
        <button className="add-to-cart">Add to Cart</button>
      </div>
    </div>
  );
};

export default ProductDetail;
